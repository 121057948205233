import { SVG } from 'assets/imagePath';
import { Box } from 'ui-organisms';
import { Button, Dropdown } from 'ui-atoms';
import { Footer, GridTable, HeaderApp, Notes } from 'ui-molecules';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from 'constant/pagination';
import { useAuth } from 'hooks/useAuth';
import DefaultLayout from 'layouts/DefaultLayout';
import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ITableColumn } from 'types';
import { getRowsByPage, ObjectUtils } from 'utils';
import { useApiCall } from 'hooks';
import {
  getListClinicianByOrganizationIdAPI,
  getListDocumentSpecialistByOrganizationIdAPI,
  getListMedicalCoderByOrganizationIdAPI,
  getListNotesRecordsAPI,
  getListNotesRecordsByClinicianAPI,
  getListOrganizationAdminByOrganizationIdAPI,
  getListOrganizationsAPI,
  getListOwnerByOrganizationIdAPI,
  getListSystemAdminByOrganizationIdAPI,
  updateTreatmentRecordAPI,
} from 'services';
import { GlobalContext } from 'context/GlobalContext';
import { APP_ROLE, SET_LOADING_MODAL } from 'constant';
import { FiPlus } from 'react-icons/fi';
import { FormProvider, useForm } from 'react-hook-form';
import { filter } from 'lodash';
import cn from 'classnames';
import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { RouterPath } from '../../routes/router.constant';

const statusOptions = [
  { label: 'New', value: 'New', color: '#DBDBDB' },
  { label: 'DS question', value: 'DS question', color: '#A895E2' },
  { label: 'Schedule', value: 'Schedule', color: '#8B8B8B' },
  { label: 'Transcribe', value: 'Transcribe', color: '#E48BB5' },
  { label: 'DS review QI', value: 'DS review QI', color: '#FECB6F' },
  { label: 'Transfer', value: 'Transfer', color: '#FF8C00' },
  { label: 'To code', value: 'To code', color: '#55ABE5' },
  { label: 'Coding', value: 'Coding', color: '#C0C0C0' },
  { label: 'CO question', value: 'CO question', color: '#33BAB1' },
  { label: 'CO review QI', value: 'CO review QI', color: '#F07D88' },
  { label: 'Tasked EMR', value: 'Tasked EMR', color: '#FFF100' },
  { label: 'Submitted', value: 'Submitted', color: '#5FBE7D' },
];

const NoteList = () => {
  const { CURRENT_ROLE } = useAuth();
  const { dispatch } = useContext(GlobalContext);
  const [notesList, setNotesList] = useState([]);

  const [fetchNote, loadingNote, originNoteList] = useApiCall(
    getListNotesRecordsAPI
  );
  const [fetchNoteByRole, loadingNoteByRole] = useApiCall(
    getListNotesRecordsByClinicianAPI
  );
  const [fetchOrgList, loadingOrgList, orgList] = useApiCall(
    getListOrganizationsAPI
  );
  const [fetchClinicianList, loadingClinicianList, clinicianList] = useApiCall(
    getListClinicianByOrganizationIdAPI
  );
  const [
    fetchDocumentSpecialistList,
    loadingDocumentSpecialistList,
    documentSpecialist,
  ] = useApiCall(getListDocumentSpecialistByOrganizationIdAPI);
  const [fetchMedicalCoderList, loadingMedicalCoderList, medicalCoder] =
    useApiCall(getListMedicalCoderByOrganizationIdAPI);
  const [fetchSystemAdminList, loadingSystemAdminList, systemAdmin] =
    useApiCall(getListSystemAdminByOrganizationIdAPI);
  const [fetchOwnerList, loadingOwnerList, owner] = useApiCall(
    getListOwnerByOrganizationIdAPI
  );
  const [
    fetchOrganizationAdminList,
    loadingOrganizationAdminList,
    organizationAdmin,
  ] = useApiCall(getListOrganizationAdminByOrganizationIdAPI);
  const [updateTreatmentRecord] = useApiCall(updateTreatmentRecordAPI);
  const [toggle, setToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [fontSize, setFontSize] = useState(12);
  const [rows, setRows] = useState([]);
  const [orderType, setOrderType] = useState<'asc' | 'desc'>('desc');
  const [search, setSearch] = useState<string>('');
  const [sortByField, setSortByField] = useState<string>('createdAt');
  const navigate = useNavigate();
  const titleOpts = [{ label: 'List', value: 'title-1' }];

  const getInitialData = useCallback(async () => {
    const data = await fetchNote('all');
    setNotesList(data);
    fetchOrgList();
  }, []);

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload:
        loadingNote ||
        loadingOrgList ||
        loadingClinicianList ||
        loadingNoteByRole ||
        loadingDocumentSpecialistList ||
        loadingMedicalCoderList ||
        loadingSystemAdminList ||
        loadingOwnerList ||
        loadingOrganizationAdminList,
    });
  }, [
    dispatch,
    loadingNote,
    loadingOrgList,
    loadingClinicianList,
    loadingNoteByRole,
    loadingDocumentSpecialistList,
    loadingMedicalCoderList,
    loadingSystemAdminList,
    loadingOwnerList,
    loadingOrganizationAdminList,
  ]);

  useEffect(() => {
    const sortedList = orderBy(notesList, [sortByField], [orderType]);
    const newRows = getRowsByPage(sortedList, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage, notesList, orderType, sortByField]);

  const renderDateTime = (val) => {
    const d = new Date(val);
    return d.toLocaleString();
  };

  const renderClassStatus = (val) => {
    switch (true) {
      case val === 'DS question': {
        return `bg-[#A895E2]`;
      }
      case val === 'Schedule': {
        return `bg-[#8B8B8B]`;
      }
      case val === 'Transcribe': {
        return `bg-[#E48BB5]`;
      }
      case val === 'DS review QI': {
        return `bg-[#FECB6F]`;
      }
      case val === 'Transfer': {
        return `bg-[#FF8C00]`;
      }
      case val === 'To code': {
        return `bg-[#55ABE5]`;
      }
      case val === 'Coding': {
        return `bg-[#C0C0C0]`;
      }
      case val === 'CO question': {
        return `bg-[#33BAB1]`;
      }
      case val === 'CO review QI': {
        return `bg-[#F07D88]`;
      }
      case val === 'Tasked EMR': {
        return `bg-[#FFF100]`;
      }
      case val === 'Submitted': {
        return `bg-[#5FBE7D]`;
      }
      default:
        return `bg-[#DBDBDB]`;
    }
  };

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  const column: ITableColumn[] = [
    {
      field: 'status_table',
      headerName: 'Status',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
      renderCell: (row) => {
        return (
          <div
            className={cn(
              'w-fit min-w-[118px] text-center mx-auto z-0',
              renderClassStatus(row.noteStatus)
            )}
          >
            <Dropdown.Standard
              CustomButton={() => (
                <div className="flex items-center">
                  <div className="px-2.5 py-[2px] rounded-full">
                    <p className="text-xs uppercase">
                      {row.noteStatus === 'NEW - Ready to Transcribe'
                        ? statusOptions[0].value
                        : row.noteStatus}
                    </p>
                  </div>

                  <img
                    className="icon-app"
                    src={SVG.chevron_down}
                    alt="chevron_down"
                  />
                </div>
              )}
              options={statusOptions}
              onChangeValue={async (data) => {
                await updateTreatmentRecord({
                  organizationId: row?.organizationId,
                  treatmentRecordId: row?.treatmentRecordId,
                  body: {
                    ...row,
                    noteStatus: data.value,
                  },
                });
                fetchNote('all');
              }}
            />
          </div>
        );
      },
    },
    {
      field: 'dateOfService',
      headerName: 'Date of Service',
      renderCell: (row) => {
        return <>{row?.dateOfService.slice(0, 10)}</>;
      },
    },
    {
      field: 'createdAt',
      headerName: (
        <span
          className="hover:cursor-pointer"
          onClick={() => {
            setSortByField('createdAt');
            setOrderType(orderType === 'asc' ? 'desc' : 'asc');
          }}
        >
          Date sent
        </span>
      ),
      renderCell: (row) => {
        return <>{renderDateTime(row?.createdAt)}</>;
      },
    },
    {
      field: 'clinician',
      headerName: 'Clinician',
      rowStyle: {
        minWidth: '136px',
      },
      renderCell: (row) => {
        let data: any = {};
        if (ObjectUtils.isJson(row?.clinician))
          data = JSON.parse(row?.clinician || '');
        return <>{`${data?.firstName}, ${data?.lastName}`}</>;
      },
    },

    {
      field: 'organizationName',
      headerName: 'Organization',
      renderCell: (row) => (
        <>
          {row?.organizationName === 'empty_flag' ? '' : row?.organizationName}
        </>
      ),
    },
    {
      field: 'patientInitials',
      headerName: (
        <span
          className="hover:cursor-pointer"
          onClick={() => {
            setSortByField('patientInitials');
            setOrderType(orderType === 'asc' ? 'desc' : 'asc');
          }}
        >
          Patient
        </span>
      ),
      renderCell: (row) => (
        <>{row?.patientInitials === 'empty_flag' ? '' : row?.patientInitials}</>
      ),
    },
    {
      field: 'documentSpecialist',
      headerName: 'DS',
      renderCell: (row) => (
        <>
          {row?.documentSpecialist === 'empty_flag'
            ? ''
            : row?.documentSpecialist}
        </>
      ),
    },
    {
      field: 'medicalCoder',
      headerName: 'CO',
      renderCell: (row) => (
        <>{row?.medicalCoder === 'empty_flag' ? '' : row?.medicalCoder}</>
      ),
    },
    {
      field: 'manager',
      headerName: 'MGR',
    },
    {
      field: 'ap',
      headerName: 'AP',
      renderCell: (row) => {
        return (
          <FiPlus
            className="text-error w-4 h-4 cursor-pointer"
            onClick={() => {}}
          />
        );
      },
    },
    {
      field: 'dsCreateAppointment',
      headerName: 'APPT',
    },
    {
      field: 'dsQ',
      headerName: 'DS?',
      hideCol: CURRENT_ROLE === APP_ROLE.CO,
      renderCell: (row) => {
        return (
          <img
            src={SVG.mark_question_green}
            alt="ds-icon"
            className="mx-auto icon-app"
          />
        );
      },
    },
    {
      field: 'coQ',
      headerName: 'CO?',
      hideCol: CURRENT_ROLE === APP_ROLE.DS,
      renderCell: (row) => {
        return (
          <img
            src={SVG.mark_question_green}
            alt="ds-icon"
            className="mx-auto icon-app"
          />
        );
      },
    },
    {
      field: 'msgNote',
      headerName: 'Note Message',
      renderCell: (row) => {
        return (
          <p className="text-xs text-error">
            {row?.msgNote === 'empty_flag' ? '' : row?.msgNote}
          </p>
        );
      },
    },
    {
      field: 'msgImportant',
      headerName: 'Important',
      renderCell: (row) => {
        return (
          <p className="text-xs text-error font-[600]">
            {row?.msgImportant === 'empty_flag' ? '' : row?.msgImportant}
          </p>
        );
      },
    },
    {
      field: 'dsDeadline',
      headerName: 'DS DL',
    },
    {
      field: 'coDeadline',
      headerName: 'CO DL',
    },
    {
      field: 'actions',
      headerName: ' ',
      renderCell: (row) => {
        return (
          <FiPlus
            className="text-gray-active w-4 h-4 cursor-pointer"
            onClick={() => {
              navigate(`/note/${row?.userRecordId}/${row?.treatmentRecordId}`);
            }}
          />
        );
      },
    },
  ];

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleFilterByOrgId = useCallback(
    async (id: string) => {
      const data = await fetchNote(id);
      console.log('noteListByOrg', data);
      setNotesList(data);
      setCurrentPage(DEFAULT_PAGE);
      setPageSize(DEFAULT_LIMIT);
      await fetchClinicianList(id);
      await fetchDocumentSpecialistList(id);
      await fetchMedicalCoderList(id);
      await fetchSystemAdminList(id);
      await fetchOwnerList(id);
      await fetchOrganizationAdminList(id);
    },
    [fetchNote]
  );

  const handleFilterByRole = useCallback(
    async (organizationId: string, userRecordId: string) => {
      const data = await fetchNoteByRole({
        organizationId: organizationId,
        userRecordId: userRecordId,
      });
      console.log('noteListByClinician', data);
      setNotesList(data);

      setCurrentPage(DEFAULT_PAGE);
      setPageSize(DEFAULT_LIMIT);
    },
    [fetchNoteByRole]
  );

  const handleFilterStatus = useCallback(
    async (status: string) => {
      setPageSize(DEFAULT_LIMIT);
      setCurrentPage(DEFAULT_PAGE);
      const data = await fetchNote('all');
      const newNotesList =
        status === 'all' ? data : filter(data, ['noteStatus', status]);
      setNotesList(newNotesList);
      setPageSize(DEFAULT_LIMIT);
      setCurrentPage(DEFAULT_PAGE);
    },
    [fetchNote]
  );

  const handleSearch = useCallback(
    (keyword: string) => {
      setPageSize(DEFAULT_LIMIT);
      setCurrentPage(DEFAULT_PAGE);
      setSearch(keyword);
      const searchResult = originNoteList.filter(
        (note) => note?.patientInitials.toLowerCase().indexOf(keyword) > -1
      );
      const sortedList = orderBy(searchResult, ['createdAt'], [orderType]);
      const newRows = getRowsByPage(sortedList, DEFAULT_LIMIT, DEFAULT_PAGE);
      setRows(newRows);
    },
    [orderType, originNoteList]
  );

  //Filter
  const form = useForm({
    defaultValues: {},
    // resolver: FormResolver(useLoginSchema())
  });

  const handleFilter = (data) => {
    console.log(data);
  };

  return (
    <DefaultLayout>
      <HeaderApp.List
        toggle={toggle}
        setToggle={setToggle}
        setFontSize={setFontSize}
        viewName={'Notes List'}
        title={{ options: titleOpts }}
      />
      <div className="w-full h-[calc(100vh-320px)] overflow-y-hidden flex flex-col flex-1 items-center p-2.5">
        <div className="flex items-center justify-between w-full">
          <p className="font-normal">
            <span className="mr-1">{notesList?.length || ''}</span>
            Record
          </p>
          <div className="px-2.5">
            <Button.White
              className="px-4"
              color="white"
              label={`${pageSize} Records per page`}
              icon={SVG.chevron_down}
              onClick={() => {}}
            />
          </div>
        </div>

        <div className="flex gap-2.5 w-full mt-2.5">
          <Box.Standard
            className={`w-[180px]  transition-all ${
              toggle ? '' : '-translate-x-[150%] fixed'
            }`}
          >
            <div className="w-full h-[calc(100vh-280px)] overflow-y-auto z-0">
              <FormProvider {...form}>
                <form>
                  <Notes.Filter
                    orgList={orgList}
                    clinicianList={clinicianList}
                    dsList={documentSpecialist}
                    mcList={medicalCoder}
                    saList={systemAdmin}
                    ownerList={owner}
                    oaList={organizationAdmin}
                    onClearFilter={() => form.reset({})}
                    onSubmitFilter={form.handleSubmit(handleFilter)}
                    selectOrgId={handleFilterByOrgId}
                    selectRoleId={handleFilterByRole}
                    onClearSearchBox={() => handleSearch('')}
                    selectStatus={handleFilterStatus}
                    searchValue={search}
                    onChangeSearch={handleSearch}
                  />
                </form>
              </FormProvider>
            </div>
          </Box.Standard>
          <div className="relative flex-1">
            <Box.Standard className="flex flex-col w-full">
              <div className="w-full h-full overflow-y-auto z-0">
                <GridTable
                  column={column}
                  row={rows}
                  checkboxes={false}
                  fontSize={fontSize}
                />
              </div>
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer
        pagination={{
          total: isEmpty(search) ? notesList?.length : rows?.length,
          currentPage: currentPage,
          pageSize: pageSize,
          onChangeCurrentPage: handleChangePage,
          onChangePageSize: handleChangePageSize,
        }}
      />
    </DefaultLayout>
  );
};

export default NoteList;

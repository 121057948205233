import { Button } from 'ui-atoms';
import { Form } from 'ui-molecules';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IInvitationStep1FormValues } from 'types';

export interface IConfirmDetails {
  step: number;
  onClick: {
    onAccept: (step) => void;
    onDecline: () => void;
  };
}

const optionsTimeZone = [
  {
    label: 'EST',
    value: 'est',
  },
  {
    label: 'EST 1',
    value: 'est_1',
  },
];
const degreeOptions = [
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'MD 2',
    value: 'md_2',
  },
];
const specialtyOptions = [
  {
    label: 'P - FM',
    value: 'p_fm',
  },
  {
    label: 'P - FM 2',
    value: 'p_fm2',
  },
];

export default function ConfirmDetails({ step, onClick }: IConfirmDetails) {
  const { onAccept, onDecline } = onClick;

  const form = useForm<IInvitationStep1FormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      cellPhone: '',
      servicePhone: '',
      timeZone: '',
      degree: '',
      specialty: '',
    },
  });

  useEffect(() => {}, []);

  return (
    <div className="max-w-[280px] w-full h-auto">
      <div className="w-full h-full flex flex-col  gap-[30px]">
        <h2 className="text-center text-xl font-bold">Confirm Details</h2>
        <FormProvider {...form}>
          <form className="w-full h-auto grid  grid-flow-row gap-y-5">
            <Form.TextInput
              name="firstName"
              label="First Name (Goes by)"
              inputProps={{
                id: 'firstName',
                placeholder: 'First Name',
                className: 'ring-1',
              }}
            />
            <Form.TextInput
              name="lastName"
              label="Last Name"
              inputProps={{
                id: 'lastName',
                placeholder: 'Last Name',
                className: 'ring-1',
              }}
            />
            <Form.TextInput
              name="email"
              label="Email"
              className="bg-pearl"
              inputProps={{
                id: 'email',
                placeholder: 'Email',
                className: 'ring-1',
              }}
            />
            <Form.TextInput
              name="cellPhone"
              label="Cell Phone"
              inputProps={{
                id: 'cellPhone',
                placeholder: 'Cell Phone',
                className: 'ring-1',
              }}
            />
            <Form.TextInput
              name="servicePhone"
              label="Service Phone"
              inputProps={{
                id: 'servicePhone',
                placeholder: 'Service Phone',
                className: 'ring-1',
              }}
            />
            <Form.SelectInput
              options={optionsTimeZone}
              name="timeZone"
              label="Time zone"
            />
            <Form.SelectMulti
              options={degreeOptions}
              name="degree"
              label="Degree"
            />
            <Form.SelectMulti
              options={specialtyOptions}
              name="specialty"
              label="Specialty"
            />
          </form>
        </FormProvider>
        <div className="flex flex-row gap-5">
          <Button.White
            color="white"
            className="flex-1 py-3"
            label="Decline"
            onClick={onDecline}
          />

          <Button.Primary
            className="flex-1 py-3"
            label={'Accept'}
            onClick={form.handleSubmit((data) => {
              console.log(data);
              onAccept(step + 1);
            })}
          />
        </div>
      </div>
    </div>
  );
}

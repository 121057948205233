import { Box } from 'ui-organisms';
import { Footer, Form, HeaderApp } from 'ui-molecules';
import EditableVerticalTable from 'ui-molecules/gridTable/EditableVerticalTable';
import { APP_ROLE, SET_LOADING_MODAL, TIMEZONE_OPTIONS } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getOrganizationListAPI,
  getUserAPI,
  inviteUserAPI,
  updateUserAPI,
} from 'services';
import { ITableColumn } from 'types';
import { capitalizeName } from 'utils/text';
import { useAuth } from '../../hooks/useAuth';
import { RouterPath } from '../../routes/router.constant';

interface IManagerEditPage {}

const ManagerEditPage: React.FC<IManagerEditPage> = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { userInfo } = state;
  const { CURRENT_ROLE } = useAuth();

  const navigate = useNavigate();
  const { managerId } = useParams();
  const form = useForm({});
  const [fetchManager, loading, managerDetail] = useApiCall(getUserAPI);
  const [fetchOrganization, , organizationData] = useApiCall(
    getOrganizationListAPI
  );
  const [inviteUser] = useApiCall(inviteUserAPI);
  const [updateUser] = useApiCall(updateUserAPI);
  const [orgOptions, setOrgOptions] = useState([]);
  const isNew = managerId?.toLowerCase() === 'new';
  const titleOpts = [
    { label: `${isNew ? 'Add' : 'Edit'} Details`, value: 'title-1' },
  ];
  const actionOpts = [
    {
      label: 'Action',
      value: 'edit',
      onClick: () => {},
    },
  ];
  const viewName = `${isNew ? 'Add' : 'Edit'} Manager Details`;

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  useEffect(() => {
    if (organizationData || !!organizationData?.length) {
      let options = [];
      organizationData?.forEach((item) => {
        options.push({
          label: item?.orgFullName,
          value: item?.corporateRecordId,
        });
      });
      setOrgOptions(options);
    }
  }, [organizationData]);

  useEffect(() => {
    if (isNew || !userInfo) return;
    fetchManager({
      primaryAccountId: userInfo?.['custom:superId'],
      userRecordId: managerId,
    });
    fetchOrganization(userInfo?.['custom:superId']);
  }, [managerId, userInfo]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [loading]);

  useEffect(() => {
    if (!managerDetail || isNew) return;
    Object.keys(managerDetail).forEach((key) => {
      if (key === 'dateStarted') {
        form.setValue(key, new Date(managerDetail?.[key]));
        return;
      }
      form.setValue(key, managerDetail?.[key]);
    });
  }, [managerDetail]);

  const managerDetailColumn: ITableColumn[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      placeholder: '?',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      placeholder: '?',
    },
    {
      field: 'middleName',
      headerName: 'Middle Name',
      placeholder: '?',
    },
    {
      field: 'initials',
      headerName: 'Initials',
      placeholder: '?',
    },
    {
      field: 'role',
      headerName: 'Role',
      placeholder: '?',
    },
    {
      field: 'degree',
      headerName: 'Degree',
      placeholder: '?',
    },
    {
      field: 'specialty',
      headerName: 'Specialty Experience',
      placeholder: '?',
    },
    {
      field: 'organization',
      headerName: 'Organization',
      placeholder: '?',
    },
    {
      field: 'organizationFullName',
      headerName: 'Organization Full Name',
      placeholder: '?',
    },
  ];

  const managerContactColumn: ITableColumn[] = [
    // {
    //   field: 'email',
    //   headerName: 'Email',
    //   placeholder: '?'
    // },
    {
      field: 'cellPhone',
      headerName: 'Cell phone',
      placeholder: '?',
    },
    {
      field: 'servicePhone',
      headerName: 'Service Phone',
      placeholder: '?',
    },
    {
      field: 'locations',
      headerName: 'Location',
      placeholder: '?',
    },
    {
      field: 'timeZone',
      headerName: 'Time Zone',
      renderCell(row) {
        return (
          <Form.SelectInput
            defaultValue={TIMEZONE_OPTIONS[0]}
            options={TIMEZONE_OPTIONS}
            name="timeZone"
            className="w-full"
            text
          />
        );
      },
    },
    {
      field: 'specialty',
      headerName: 'Specialty Experience',
      placeholder: '?',
    },
  ];

  const managerOtherColumn: ITableColumn[] = [
    {
      field: 'clinician',
      headerName: 'Clinician',
      placeholder: '?',
    },
    {
      field: 'specialtyAssignments',
      headerName: 'Specialty Assignments',
      renderCell(row) {
        return (
          <Form.SelectInput
            // options={orgOptions}
            defaultValue={{ label: '', value: '' }}
            options={[{ label: 'Orthobiologics', value: 'sc' }]}
            name="specialtyAssignments"
            text
            className="w-full"
          />
        );
      },
    },
    {
      field: 'departmentAssignments',
      headerName: 'Department Assignments',
      renderCell(row) {
        return (
          <Form.SelectInput
            // options={orgOptions}
            defaultValue={{ label: '', value: '' }}
            options={[{ label: 'EMR System', value: 'sc' }]}
            name="departmentAssignments"
            text
            className="w-full"
          />
        );
      },
    },

    {
      field: 'organizationAssignments',
      headerName: 'Organization Assignments',
      placeholder: '?',
    },

    {
      field: 'primaryAssignments',
      headerName: 'Primary TR Assignments',
      placeholder: '?',
    },
    {
      field: 'backupAssignments',
      headerName: 'Backup TR Assignments',
      placeholder: '?',
    },

    {
      field: 'generalAssignments',
      headerName: 'General TR Work Assignments',
      placeholder: '?',
    },
  ];

  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      if (isNew) {
        await inviteUser({
          ...data,
          initials: capitalizeName(
            data?.firstName,
            data?.lastName,
            data?.middleName
          ),
          userRole: 'Manager',
          primaryAccountId: userInfo?.['custom:superId'],
        });
      } else {
        // await updateUser({
        //   body: {
        //     ...data,
        //     initials: capitalizeName(data?.firstName, data?.lastName, data?.middleName),
        //     userRole: 'Manager'
        //   },
        //   userRecordId: dcId,
        //   primaryAccountId: userInfo?.['custom:superId']
        // });
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
      // navigate(`/organization/${organizationId}`);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
  };

  return (
    <DefaultLayout>
      <HeaderApp.Edit
        title={{ options: titleOpts }}
        actions={{ options: actionOpts }}
        onSave={form.handleSubmit(handleSubmitFunc)}
        viewName={viewName}
      />
      <div>
        <p className="text-right self-end text-xs p-2.5">
          Last Update: 23 hrs – 40 Min Ago
        </p>
      </div>
      <Box.Standard className="my-2.5">
        <FormProvider {...form}>
          <form className="grid grid-cols-3 gap-[10px]">
            <EditableVerticalTable
              isEdit={true}
              columns={managerDetailColumn}
              row={!isNew && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={true}
              columns={managerContactColumn}
              row={!isNew && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={true}
              columns={managerOtherColumn}
              row={!isNew && form.getValues()}
            />
          </form>
        </FormProvider>
      </Box.Standard>
      <Footer />
    </DefaultLayout>
  );
};

export default ManagerEditPage;

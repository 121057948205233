import { Auth } from 'aws-amplify';
import { SET_LOADING_MODAL, SET_USER_INFO } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RouterPath } from './router.constant';

export const PublicRoute: React.FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  const checkAuth = async () => {
    try {
      setIsLoading(true);
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      console.log('authenticatedUser', authenticatedUser?.attributes);
      if (authenticatedUser) {
        setIsAuth(true);
        dispatch({
          type: SET_USER_INFO,
          payload: authenticatedUser?.attributes,
        });
        localStorage.setItem(
          'CODED_NOTE_userData',
          JSON.stringify(authenticatedUser?.attributes)
        );
      } else {
        setIsAuth(false);
      }
    } catch (err) {
      setIsAuth(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: isLoading,
    });
  }, [isLoading]);

  if (isLoading) {
    return <></>;
  }

  if (!isAuth) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return <Navigate to={RouterPath.NOTES} />;
};

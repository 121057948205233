import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { SVG } from 'assets/imagePath';
import { Auth } from 'aws-amplify';
import { Button } from 'ui-atoms';
import { Form, Auth as AuthLayout } from 'ui-molecules';
import { useNavigateSearch } from 'hooks';
import { useRegisterStep3Schema } from 'pages/auth/register/register.schema';
import { FC, memo, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IRegisterStep3FormValues } from 'types';
import { SET_LOADING_MODAL } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { toast } from 'react-toastify';

const RegisterStep3: FC = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const navigateSearch = useNavigateSearch();
  const { registerForm } = state;

  useEffect(() => {
    if (!registerForm) {
      navigateSearch('/register', { step: '1' });
    }
  }, []);

  const form = useForm<IRegisterStep3FormValues>({
    defaultValues: {
      code: '',
    },
    resolver: FormResolver(useRegisterStep3Schema()),
  });

  const onSubmit = async (values: IRegisterStep3FormValues) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      const response = await Auth.confirmSignUp(
        registerForm?.username.toLowerCase().trim(),
        values?.code
      );
      if (response?.toLowerCase() !== 'success') return;
      console.log('response', response);
      const signInRes = await Auth.signIn(
        registerForm?.username.toLowerCase().trim(),
        registerForm?.password
      );
      console.log('signInRes success', signInRes);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
      navigateSearch('/register', { step: '4' });
    } catch (err) {
      toast.error(err);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }
  };

  return (
    <div className="max-w-[280px] w-full  h-full  flex flex-col mx-auto justify-center items-center ">
      <AuthLayout.Header
        icon={SVG.register_confirm}
        title="Confirm registration!"
        subtitle=""
        canGoBack
      />

      <FormProvider {...form}>
        <form className="w-full">
          <Form.PhoneInput
            className="mt-[30px] w-full"
            name="code"
            inputProps={{
              id: 'code',
              placeholder: 'Confirmation Code',
            }}
          />

          <Button.Primary
            className="mt-[30px] py-[12px]"
            label="Confirm"
            onClick={form.handleSubmit(onSubmit)}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(RegisterStep3);

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form } from 'ui-molecules';
import { useActiveStep1Schema } from '../../pages/auth/activeNewUser/active.schema';
import { ActiveNewUserStep1 } from 'types';
import { Button } from '../../ui-atoms';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { SET_ACTIVE_FORM, SET_LOADING_MODAL } from '../../constant';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { SVG } from '../../assets/imagePath';
import { RouterPath } from '../../routes/router.constant';

type Props = {
  nextStep: () => void;
};
export const ActiveUserStep1 = ({ nextStep }: Props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { activeForm } = state;
  const { email } = useParams();
  const navigate = useNavigate();

  console.log('email', email);
  const form = useForm<ActiveNewUserStep1>({
    defaultValues: {
      password: '',
    },
    mode: 'onChange',
    resolver: FormResolver(useActiveStep1Schema()),
  });

  const onSubmit = async (values: ActiveNewUserStep1) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      dispatch({
        type: SET_ACTIVE_FORM,
        payload: {
          email: activeForm?.email,
          password: values?.password?.trim(),
        },
      });
      const signInRes = await Auth.signIn(
        activeForm?.email,
        values?.password?.trim()
      );
      console.log('signInRes success', signInRes);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
      nextStep();
    } catch (err) {
      console.log(err);
      toast(err.message, {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col gap-y-5 items-center justify-center">
      <img src={SVG.avatar_circle} width={110} alt="logo" className="mb-10" />
      <h1 className="text-xl font-bold">Sign In with Temporary password</h1>
      <p className="flex items-baseline text-center text-[#1D2533]">
        Please check your email for the <br />
        temporary password.
      </p>

      <FormProvider {...form}>
        <form className="flex flex-col gap-y-5 w-[200px]">
          <Form.PasswordInput
            className="w-full"
            name="password"
            inputProps={{ id: 'password', placeholder: 'Temporary Password' }}
          />

          <div className="flex items-center gap-x-5 justify-between">
            <Button.White
              label="Decline"
              onClick={() => navigate(RouterPath.LOGIN)}
            />
            <Button.Primary
              label="Accept"
              className="h-10"
              onClick={form.handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

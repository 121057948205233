import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { SET_USER_INFO } from 'constant';
import { Button } from 'ui-atoms';
import { Form } from 'ui-molecules';
import { FormProvider, useForm } from 'react-hook-form';
import { IConfirmDetailInvitation } from 'types';
import { GlobalContext } from 'context/GlobalContext';
import { useContext, useEffect } from 'react';
import { useApiCall } from 'hooks';
import { getNewUserDetailAPI, updateNewUserDetailAPI } from 'services';
import { SET_LOADING_MODAL, SET_CONFIRM_INFO } from 'constant';
import { useConfirmDetailSchema } from './invitation.schema';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';

const optionsTimeZone = [
  {
    label: 'EST',
    value: 'est',
  },
  {
    label: 'EST 1',
    value: 'est_1',
  },
];
const degreeOptions = [
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'MD 2',
    value: 'md_2',
  },
];
const specialtyOptions = [
  {
    label: 'P - FM',
    value: 'p_fm',
  },
  {
    label: 'P - FM 2',
    value: 'p_fm2',
  },
];

export default function ConfirmDetails() {
  const navigate = useNavigate();
  const { dispatch, state } = useContext(GlobalContext);
  const { email } = useParams();
  const { userInfo } = state;
  const [fetchUserDetail, loading, userDetailData] =
    useApiCall(getNewUserDetailAPI);
  const [fetchUpdateUserDetail] = useApiCall(updateNewUserDetailAPI);
  const form = useForm<IConfirmDetailInvitation>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      cellPhone: '',
      servicePhone: '',
      timeZone: '',
      degree: '',
      specialty: '',
      accountStatus: '',
    },
    resolver: FormResolver(useConfirmDetailSchema()),
  });

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [loading]);

  useEffect(() => {
    console.log(form.getValues);
    fetchUserDetail({
      organizationId: userInfo?.['custom:organizationId'],
      userRecordId: userInfo?.['sub'],
    });
  }, []);

  useEffect(() => {
    if (userDetailData) {
      form.reset(userDetailData);
      form.setValue('accountStatus', 'REGISTERED');
    }
  }, [userDetailData]);

  const onSubmit = async (values: any) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });

      const response = await fetchUpdateUserDetail({
        body: values,
        organizationId: userInfo?.['custom:organizationId'],
        userRecordId: userInfo?.['sub'],
      });

      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);

      if (!response) return;
      navigate(RouterPath.INVITATION_CHANGE_PASSWORD);
    } catch (err) {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }
  };

  return (
    <div className="max-h-screen h-full w-full flex flex-col relative">
      <div className="h-full w-full flex-1 flex justify-center items-center p-2.5 overflow-auto">
        <div className="max-w-[280px] w-full h-auto">
          <div className="w-full h-full flex flex-col  gap-[30px]">
            <h2 className="text-center text-xl font-bold">Confirm Details</h2>
            <FormProvider {...form}>
              <form className="w-full h-auto grid  grid-flow-row gap-y-5">
                <Form.TextInput
                  name="firstName"
                  label="First Name (Goes by)"
                  inputProps={{
                    id: 'firstName',
                    placeholder: 'First Name',
                    className: 'ring-1',
                  }}
                />
                <Form.TextInput
                  name="lastName"
                  label="Last Name"
                  inputProps={{
                    id: 'lastName',
                    placeholder: 'Last Name',
                    className: 'ring-1',
                  }}
                />
                <Form.TextInput
                  name="email"
                  label="Email"
                  className="bg-pearl"
                  inputProps={{
                    id: 'email',
                    placeholder: 'Email',
                    className: 'ring-1',
                  }}
                />
                <Form.TextInput
                  name="cellPhone"
                  label="Cell Phone"
                  inputProps={{
                    id: 'cellPhone',
                    placeholder: 'Cell Phone',
                    className: 'ring-1',
                  }}
                />
                <Form.TextInput
                  name="servicePhone"
                  label="Service Phone"
                  inputProps={{
                    id: 'servicePhone',
                    placeholder: 'Service Phone',
                    className: 'ring-1',
                  }}
                />
                <Form.SelectInput
                  options={optionsTimeZone}
                  name="timeZone"
                  label="Time zone"
                  inputProps={{
                    className: 'justify-between',
                  }}
                />
                <Form.SelectMulti
                  options={degreeOptions}
                  name="degree"
                  label="Degree"
                />
                <Form.SelectMulti
                  options={specialtyOptions}
                  name="specialty"
                  label="Specialty"
                />
              </form>
            </FormProvider>
            <div className="flex flex-row gap-5">
              <Button.White
                color="white"
                className="flex-1 py-3"
                label="Decline"
                onClick={() => {}}
              />

              <Button.Primary
                className="flex-1 py-3"
                label={'Accept'}
                onClick={form.handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from 'react';
import { IAttachmentsTable, ITableColumn } from '../../../types';
import { Input } from '../../../ui-atoms';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  rows: IAttachmentsTable[];
  setRows: (rows: IAttachmentsTable[]) => void;
  updateValue: (id: string, row: IAttachmentsTable) => void;
  fontSize: number;
};

const AttachmentsColumn: ITableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'format',
    headerName: 'Format',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'author',
    headerName: 'Author',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'comment',
    headerName: 'Comment',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];
export const Attachments = ({
  rows,
  setRows,
  updateValue,
  fontSize,
}: Props) => {
  async function onChange(e) {
    const file = e.target.files[0];
    console.log('file', file);
    onAdd({
      name: file.name,
      format: file.type,
      key: file.name,
      author: '',
      comment: '',
    });
    try {
      await Storage.put(file.name, file, {
        contentType: 'image/png',
      });
    } catch (error) {
      console.log('Error uploading file: ', error);
    }
  }

  const handleChangeValue = (field: string, value: string, row) => {
    if (field === 'name') {
      console.log('name');
      return updateValue(row.id, {
        ...row,
        name: value,
      });
    }
    if (field === 'format') {
      console.log('format');
      return updateValue(row.id, {
        ...row,
        format: value,
      });
    }
    if (field === 'author') {
      console.log('author');
      return updateValue(row.id, {
        ...row,
        author: value,
      });
    }
    if (field === 'comment') {
      console.log('comment');
      return updateValue(row.id, {
        ...row,
        comment: value,
      });
    }
  };

  const onAdd = (data) => {
    let rowData = [];
    rowData.push(...rows, {
      id: uuidv4(),
      name: data.name,
      format: data.format,
      key: data.key,
      author: '',
      comment: '',
    });
    setRows(rowData);
  };

  const renderRow = (row) => {
    return (
      <tr key={row.id} className="border border-t-0 hover:bg-gray-300">
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('name', e, row)}
              value={row.name}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('format', e, row)}
              value={row.format}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('author', e, row)}
              value={row.author}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('comment', e, row)}
              value={row.comment}
            />
          </div>
        </td>
      </tr>
    );
  };
  return (
    <>
      <table className={'table-auto w-full h-full z-0'}>
        <thead>
          <tr>
            {AttachmentsColumn.map((column) => {
              return (
                !column?.hideCol && (
                  <th
                    key={column.field}
                    className="p-table text-center border font-[600] whitespace-nowrap  min-w-fit"
                    style={column?.headerStyle}
                  >
                    <p style={{ fontSize: `${fontSize}px` }}>
                      {column.headerName}
                    </p>
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => {
            return renderRow(row);
          })}
          <tr className="border border-t-0 hover:cursor-pointer">
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <label
                htmlFor="dropzone-file"
                className="flex items-center w-full cursor-pointer"
              >
                <div className="w-full flex items-center">
                  <p className="w-full text-sm text-black text-opacity-50 text-center">
                    +
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={onChange}
                />
              </label>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <label
                htmlFor="dropzone-file"
                className="flex items-center w-full cursor-pointer"
              >
                <div className="w-full flex items-center">
                  <p className="w-full text-sm text-black text-opacity-50 text-center">
                    +
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={onChange}
                />
              </label>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <label
                htmlFor="dropzone-file"
                className="flex items-center w-full cursor-pointer"
              >
                <div className="w-full flex items-center">
                  <p className="w-full text-sm text-black text-opacity-50 text-center">
                    +
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={onChange}
                />
              </label>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <label
                htmlFor="dropzone-file"
                className="flex items-center w-full cursor-pointer"
              >
                <div className="w-full flex items-center">
                  <p className="w-full text-sm text-black text-opacity-50 text-center">
                    +
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={onChange}
                />
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from 'ui-organisms';
import { Builder } from 'ui-organisms/builder';
import { Button } from 'ui-atoms';
import { Footer, HeaderApp } from 'ui-molecules';
import DefaultLayout from 'layouts/DefaultLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiCall } from 'hooks';
import { getTreatmentRecordsAPI, updateTreatmentRecordAPI } from 'services';
import { GlobalContext } from '../../context/GlobalContext';
import { APP_ROLE, SET_LOADING_MODAL } from '../../constant';
import { Storage } from 'aws-amplify';
import { toast } from 'react-toastify';
import { RouterPath } from '../../routes/router.constant';
import { useAuth } from '../../hooks/useAuth';

export interface IBuilderPageProps {}

export default function BuilderPage({}: IBuilderPageProps) {
  const { CURRENT_ROLE } = useAuth();

  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const [getTreatmentRecords, loading, treatmentData] = useApiCall(
    getTreatmentRecordsAPI,
    false
  );
  const [updateTreatmentRecord] = useApiCall(updateTreatmentRecordAPI);
  const { userRecordId, treatmentRecordId } = useParams();
  const [focusInput, setFocusInput] = useState(false);
  const [info, setInfo] = useState({
    status: '',
    dateOfService: null,
    important: '',
    noteMessage: '',
    url: '',
    identityId: '',
    patient: '',
  });

  const [toggle, setToggle] = useState(false);

  const [filterData, setFilterData] = useState({
    dataInsight: {},
    dataSmart: {},
  });
  const [treatmentLog, setTreatmentLog] = useState<string>('<p></p>');

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [dispatch, loading]);

  useEffect(() => {
    if (!userRecordId || !treatmentRecordId) return navigate('/');
    getTreatmentRecords({
      userRecordId,
      treatmentRecordId,
    });
  }, [treatmentRecordId, userRecordId]);

  useEffect(() => {
    if (!loading && treatmentData) {
      setInfo({
        status:
          treatmentData?.[0]?.noteStatus === 'empty_flag'
            ? ''
            : treatmentData?.[0]?.noteStatus,
        dateOfService: treatmentData?.[0]?.dateOfService?.slice(0, 10),
        important:
          treatmentData?.[0]?.msgImportant === 'empty_flag'
            ? ''
            : treatmentData?.[0]?.msgImportant,
        noteMessage:
          treatmentData?.[0]?.msgNote === 'empty_flag'
            ? ''
            : treatmentData?.[0]?.msgNote,
        patient:
          treatmentData?.[0]?.patientInitials === 'empty_flag'
            ? ''
            : treatmentData?.[0]?.patientInitials,
        url: treatmentData?.[0]?.audioFileAttachment?.toString(),
        identityId: treatmentData?.[0]?.identityValue?.toString(),
      });
      setTreatmentLog(
        treatmentData?.[0]?.treatmentLog === 'empty_flag'
          ? '<p>Treatment:</p>'
          : treatmentData?.[0]?.treatmentLog ?? '<p>Treatment:</p>'
      );
    }
  }, [loading, treatmentData]);

  const handleSaveTreatmentLog = async (html) => {
    setTreatmentLog(html);
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      await updateTreatmentRecord({
        organizationId: treatmentData?.[0]?.organizationId,
        treatmentRecordId: treatmentData?.[0]?.treatmentRecordId,
        body: {
          ...treatmentData?.[0],
          treatmentLog: html,
        },
      });
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
      toast.success('Update success', {
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (error) {
      console.log('error', error);
      setTimeout(() => {
        toast.error(error.message, {
          hideProgressBar: true,
          autoClose: 3000,
          type: 'error',
          theme: 'colored',
        });
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
  };

  const handleSubmit = useCallback((dataInsight, dataSmart) => {
    if (dataInsight) {
      console.log(dataInsight);
    }
    if (dataSmart) {
      setFilterData((prev) => ({
        ...prev,
        dataSmart,
      }));
    }
  }, []);

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  // usage
  async function download() {
    const result = await Storage.get(info.url, {
      validateObjectExistence: true,
      expires: 20,
      level: 'protected',
      identityId: info.identityId,
      download: true,
    });
    downloadBlob(result.Body, info.url);
  }

  console.log('focusInput', focusInput);
  return (
    <DefaultLayout>
      <HeaderApp.Builder toggle={toggle} setToggle={setToggle} />
      <div className="fixed w-full top-[107px] right-0 left-0 bottom-[50px] flex-1 flex p-2.5 mx-auto gap-2.5 overflow-y-auto">
        <Box.Standard
          className={`max-w-[280px] h-fit transition-all  ${
            toggle ? 'fixed -translate-x-[150%] ' : ''
          }`}
        >
          <Builder.SmartInsight onGetData={handleSubmit} />
        </Box.Standard>
        <div className="flex-1 flex flex-col items-stretch gap-y-2.5  w-full relative">
          <Box.Standard className="h-auto px-5">
            <div
              className={`w-full grid grid-cols-2 text-xs gap-y-2.5 gap-x-5  items-center`}
            >
              <div>
                <div className="col-span-1 flex justify-between   text-dark-silver">
                  <div className="col-span-1">Patient</div>
                  <div className="col-span-2 font-bold">{info.patient}</div>
                </div>
                <div className="col-span-1 flex justify-between  text-dark-silver">
                  <div className="col-span-1">Status</div>
                  <div className="col-span-2">{info.status}</div>
                </div>
                <div className="col-span-1 flex justify-between  text-dark-silver">
                  <div className="col-span-1">CLIN?</div>
                  <div className="col-span-2 font-bold text-error">
                    Pain Level is a 7
                  </div>
                </div>
                <div className="col-span-1 flex justify-between  text-dark-silver">
                  <div className="col-span-1">&nbsp;</div>
                  <div className="col-span-2">&nbsp;</div>
                </div>
              </div>
              <div>
                <div className="col-span-1 flex justify-between  text-dark-silver">
                  <div className="col-span-1">DOS :</div>
                  <div className="col-span-2 font-bold">
                    {info.dateOfService}
                  </div>
                </div>
                <div className="col-span-1 flex justify-between  text-dark-silver">
                  <div className="col-span-1">Important:</div>
                  <div className="col-span-2 font-bold text-error">
                    {info.important}
                  </div>
                </div>

                <div className="col-span-1 flex justify-between  text-dark-silver">
                  <div className="col-span-1">Nurse Appt</div>
                  <div className="col-span-1 font-bold">
                    <span className="text-error">Y</span>/N
                  </div>
                </div>

                <div className="col-span-1 flex justify-between   text-dark-silver">
                  <div className="col-span-1">Note Message</div>
                  <div className="col-span-1 font-bold text-error">
                    {info.noteMessage}
                  </div>
                </div>
              </div>
            </div>
          </Box.Standard>

          <Box.Standard className="h-full">
            <div className="w-full h-auto flex flex-col gap-y-[30px] px-[65px] py-[50px]">
              <div className="w-full flex-1 h-full flex flex-col gap-y-4 justify-between">
                <Builder.Content
                  html={treatmentLog}
                  onSave={(html) => handleSaveTreatmentLog(html)}
                  setFocus={setFocusInput}
                />
              </div>
            </div>
          </Box.Standard>
          <Box.Standard
            className={`w-full sticky bottom-[0] h-auto flex justify-center gap-2.5 items-center flex-col ${
              toggle ? 'lg:flex-row' : 'xl:flex-row'
            }`}
          >
            <Button.Primary
              className="w-fit !p-default text-sm"
              label="Download"
              onClick={download}
            />
            <Box.Standard className="w-fit">
              <Builder.Player
                toggle={toggle}
                mediaUrl={info.url}
                identityId={info.identityId}
                isFocus={!focusInput}
              />
            </Box.Standard>
            {/*<div className="flex flex-row gap-x-2.5">*/}
            {/*  <Button.White*/}
            {/*    color="white"*/}
            {/*    className="!p-default text-sm h-fit"*/}
            {/*    label="Edit"*/}
            {/*    onClick={() => {}}*/}
            {/*  />*/}
            {/*  <Button.White*/}
            {/*    color="white"*/}
            {/*    className="!p-default text-sm h-fit"*/}
            {/*    label="Save"*/}
            {/*    onClick={() => {}}*/}
            {/*  />*/}
            {/*  <Button.Primary*/}
            {/*    className="w-fit !p-default text-sm"*/}
            {/*    label="Done"*/}
            {/*    onClick={() => {}}*/}
            {/*  />*/}
            {/*</div>*/}
          </Box.Standard>
        </div>

        <Box.Standard
          className={`max-w-[280px] h-fit transition-transform right-0  ${
            toggle ? 'fixed translate-x-[150%] ' : ''
          }`}
        >
          <Builder.SmartNote onGetData={handleSubmit} />
        </Box.Standard>
      </div>
      <Footer />
    </DefaultLayout>
  );
}

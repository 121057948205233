import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { SVG } from 'assets/imagePath';
import { Box, Clinician } from 'ui-organisms';
import { Footer, Form, GridTable, HeaderApp } from 'ui-molecules';
import { APP_ROLE, SET_LOADING_MODAL, TIMEZONE_OPTIONS } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import {
  getListOrganizationsAPI,
  getNewUserDetailAPI,
  updateClinicianAPI,
} from 'services';
import cn from 'classnames';
import { IAttachmentsTable, ICommentsTable } from '../../types';
import { v4 as uuidv4 } from 'uuid';
import { Attachments, Comments } from '../../ui-organisms/clinician/table';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { useClinicianEditSchema } from './clinician-edit.schema';
import { toast } from 'react-toastify';
import { Builder } from '../../ui-organisms/builder';
import {
  fakeColumn1,
  fakeColumn2,
  fakeColumn3,
  fakeRow1,
  fakeRow2,
  fakeRow3,
} from './data';
import isEmpty from 'lodash/isEmpty';
import { useAuth } from '../../hooks/useAuth';

const fontSize = 12;

const listFilter = [
  'Details',
  'Contact Info',
  'CL Schedule',
  'DS Assignments',
  'CO Assignments',
  'Preferences',
  'Smart Phrases',
  'Smart Notes',
  'Comments',
  'Attachments',
];
const ClinicianDetailPage = () => {
  const [fetchUser, loading, userDetail] = useApiCall(getNewUserDetailAPI);
  const [fetchOrganization, loadingOrganization, listOrganization] = useApiCall(
    getListOrganizationsAPI
  );
  const [updateUser] = useApiCall(updateClinicianAPI);
  const { CURRENT_ROLE } = useAuth();

  const navigate = useNavigate();
  const { dispatch, state } = useContext(GlobalContext);

  const { userRecordId: clinicianId, primaryAccountId } = state;

  const form = useForm({
    defaultValues: {
      organization: {
        label: '',
        value: '',
        short: '',
        initials: '',
      },
      organizationName: '',
      organizationId: '',
      important: '',
      organizationTimeZone: '',
      firstName: '',
      lastName: '',
      middleName: '',
      initials: '',
      sortName: '',
      givenName: '',
      prefix: '',
      suffix: '',
      degree: '',
      otherDegree: '',
      specialties: '',
      fellowships: '',
      recordStatus: '',
      startDate: new Date(),
      serviceLevel: '',
      priority: '',
      ranking: '',
      predictability: '',
      orgShort: '',
      orgInitials: '',
      location: '',
      department: '',
      site: '',
      preferences: '',
      siteManagerCell: '',
      principalNurse: '',
      nurseDirectPhone: '',
      primaryDocumentSpecialist: '',
      primaryAuditor: '',
      turnaroundTime: '',
    },
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: FormResolver(useClinicianEditSchema()),
  });

  const [attachmentRows, setAttachmentRows] = React.useState<
    IAttachmentsTable[]
  >([
    {
      id: uuidv4(),
      name: 'Attachments 1',
      format: 'PDF',
      author: 'Marcus Welby',
      comment: 'Contact sheet',
    },
  ]);

  const [commentRows, setCommentRows] = React.useState<ICommentsTable[]>([
    {
      id: uuidv4(),
      date: '03/18/2023',
      full_name: 'Julie Medley',
      role: 'DS',
      comment: 'Betty MacDonald',
    },
  ]);
  const [selectPage, setSelectPage] = React.useState(listFilter[0]);
  const [toggle, setToggle] = React.useState(false);

  const [notePreferencesContent, setNotePreferencesContent] = React.useState(
    `<p><strong>Note Preferences:</strong>&nbsp;</p>`
  );

  const [codePreferencesContent, setCodePreferencesContent] = React.useState(
    `<p><strong>Coding Preferences:</strong>&nbsp;</p>`
  );

  const [smartPhrasesContent, setSmartPhrasesContent] = React.useState('');
  const [smartNotesContent, setSmartNotesContent] = React.useState('');

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  useEffect(() => {
    form.setValue('organizationName', userDetail?.organizationName);
    form.setValue('organizationId', userDetail?.organizationId);
    form.setValue('firstName', userDetail?.firstName);
    form.setValue('middleName', userDetail?.middleName);
    form.setValue('lastName', userDetail?.lastName);
    form.setValue('initials', userDetail?.initials);
    form.setValue('sortName', userDetail?.sortName);
    form.setValue('givenName', userDetail?.givenName);
    form.setValue('prefix', userDetail?.prefix);
    form.setValue('suffix', userDetail?.suffix);
    form.setValue('degree', userDetail?.degree);
    form.setValue('otherDegree', userDetail?.otherDegree);
    form.setValue('specialties', userDetail?.specialties);
    form.setValue('fellowships', userDetail?.fellowships);
    form.setValue('recordStatus', userDetail?.recordStatus);
    form.setValue('serviceLevel', userDetail?.serviceLevel);
    form.setValue('priority', userDetail?.priority);
    form.setValue('ranking', userDetail?.ranking);
    form.setValue('predictability', userDetail?.predictability);
    form.setValue('important', userDetail?.important ?? userDetail?.priority);
    form.setValue(
      'organizationTimeZone',
      userDetail?.organizationTimeZone ?? userDetail?.timeZone
    );
    form.setValue('location', userDetail?.location);
    form.setValue('department', userDetail?.department);
    form.setValue('site', userDetail?.site);
    form.setValue('preferences', userDetail?.preferences);
    form.setValue('siteManagerCell', userDetail?.siteManagerCell);
    form.setValue('principalNurse', userDetail?.principalNurse);
    form.setValue('nurseDirectPhone', userDetail?.nurseDirectPhone);
    form.setValue(
      'primaryDocumentSpecialist',
      userDetail?.primaryDocumentSpecialist
    );
    form.setValue('primaryAuditor', userDetail?.primaryAuditor);
    form.setValue('turnaroundTime', userDetail?.turnaroundTime);
    userDetail?.attachments && setAttachmentRows(userDetail?.attachments);
    userDetail?.comments && setCommentRows(userDetail?.comments);
    userDetail?.notePreferencesContent &&
      setNotePreferencesContent(userDetail?.notePreferencesContent);
    userDetail?.codePreferencesContent &&
      setCodePreferencesContent(userDetail?.codePreferencesContent);
    userDetail?.smartPhrases &&
      setSmartPhrasesContent(userDetail?.smartPhrases);
    userDetail?.smartNotesContent &&
      setSmartNotesContent(userDetail?.smartNotesContent);
  }, [form, userDetail]);

  useEffect(() => {
    if (!clinicianId || !primaryAccountId) return navigate('/');
    fetchUser({
      organizationId: primaryAccountId,
      userRecordId: clinicianId,
    });
    fetchOrganization();
  }, [fetchUser, clinicianId, primaryAccountId, navigate, fetchOrganization]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading || loadingOrganization,
    });
  }, [dispatch, loading, loadingOrganization]);

  const handleSubmitFunc = async (data) => {
    console.log('data', data);
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      await updateUser({
        organizationId: primaryAccountId,
        userRecordId: clinicianId,
        body: {
          ...userDetail,
          ...data,
          title: userDetail?.title,
          userRole: userDetail?.userRole,
          email: userDetail?.email,
          cellPhone: userDetail?.cellPhone,
          attachments: attachmentRows,
          comments: commentRows,
          smartPhrases: smartPhrasesContent,
          notePreferencesContent: notePreferencesContent,
          codePreferencesContent: codePreferencesContent,
          smartNotesContent: smartNotesContent,
        },
      });
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
      toast.success('Update success', {
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (err) {
      console.log('error', err);
      setTimeout(() => {
        toast.error(err.message, {
          hideProgressBar: true,
          autoClose: 3000,
          type: 'error',
          theme: 'colored',
        });
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
  };

  const updateAttachmentRows = (id: string, row: IAttachmentsTable) => {
    const newRow = attachmentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setAttachmentRows(newRow);
  };

  const updateCommentRows = (id: number, row: ICommentsTable) => {
    const newRow = commentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setCommentRows(newRow);
  };

  const renderPart = (title: string, element: any, isMl?: boolean) => {
    return (
      <div className="w-full flex items-start gap-x-10">
        {title && (
          <div
            className={cn('text-xs font-bold py-4 w-1/4', isMl ? 'ml-9' : '')}
            style={{ fontSize: `${fontSize}px` }}
          >
            {title}
          </div>
        )}
        <div className="w-full mt-6">{element}</div>
      </div>
    );
  };

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className=" w-full flex flex-col gap-y-4 py-4">
        <div className="flex items-center gap-x-2">
          <div className="w-2/5 justify-end">
            <p
              className="text-xs font-bold text-right"
              style={{ fontSize: `${fontSize}px` }}
            >
              {headerName}
            </p>
          </div>
          <div
            className="w-full flex flex-1 items-center justify-end"
            style={{ fontSize: `${fontSize}px` }}
          >
            {cell}
          </div>
        </div>
      </div>
    );
  };

  const renderTablePart = (title: string, element: any) => {
    return (
      <div
        className="flex flex-col gap-4 items-center w-full"
        style={{ fontSize: `${fontSize}px` }}
      >
        <p className="font-bold text-center py-1">{title}</p>
        <div className="!overflow-auto  w-full max-h-full z-0">{element}</div>
      </div>
    );
  };

  const organizationOption = useMemo(() => {
    return listOrganization?.map((orgItem) => ({
      label: orgItem?.organizationName,
      value: orgItem?.organizationId,
      short: orgItem?.orgShort,
      initials: orgItem?.orgInitials,
    }));
  }, [listOrganization]);

  const organizationData = form.watch('organization');
  useEffect(() => {
    form.setValue('organizationName', organizationData.label);
    form.setValue('organizationId', organizationData.value);
    form.setValue('orgShort', organizationData.short);
    form.setValue('orgInitials', organizationData.initials);
    form.clearErrors('orgShort');
    form.clearErrors('orgInitials');
  }, [form, organizationData]);

  const handleSaveContent = (html, field) => {
    switch (true) {
      case field === 'Note Preferences': {
        return setNotePreferencesContent(html);
      }

      case field === 'Coding Preferences': {
        return setCodePreferencesContent(html);
      }
      case field === 'Smart Phrases': {
        return setSmartPhrasesContent(html);
      }
      case field === 'Smart Notes': {
        return setSmartNotesContent(html);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(form?.formState?.errors)) {
      toast.error('Missing some fields. Please check them', {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
    }
  }, [form?.formState?.errors]);

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        toggle={toggle}
        setToggle={setToggle}
        detailProps={{
          detailName: `${[userDetail?.organization]} - Details`,
          addLabel: 'Save',
          labelDetail: `Clinician Details - ${userDetail?.initials}`,
        }}
        onAdd={form.handleSubmit(handleSubmitFunc)}
        viewName="Clinician Detail"
      />
      <div className="w-full flex flex-col flex-1 justify-center items-center p-2.5 mb-[50px]">
        <div className="flex justify-between w-full">
          <div
            className="flex items-center text-xs text-black font-bold px-2.5 gap-x-[5px] cursor-pointer"
            onClick={() => navigate(RouterPath.CLINICIANS, { replace: true })}
          >
            <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
          </div>
          <p className="self-end text-sm text-right">
            Last Update: 23 hrs – 40 Min Ago
          </p>
        </div>
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <Box.Standard
            className={`max-w-[180px] h-auto w-full transition-all ${
              toggle ? '' : '-translate-x-[150%] fixed'
            }`}
          >
            <Clinician.Filter
              listPage={listFilter}
              selectPage={selectPage}
              setPage={setSelectPage}
            />
          </Box.Standard>

          <FormProvider {...form}>
            <form className="flex flex-col w-full flex-1 gap-2.5">
              {selectPage === listFilter[0] && (
                <>
                  <Box.Standard>
                    <div className="grid grid-cols-2 gap-[10px] mt-4">
                      {renderVerticalRow(
                        'Organization',
                        <Form.TextInput
                          name="organizationName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('organizationName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                          disable
                        />
                      )}
                      <div className="flex flex-col">
                        {renderVerticalRow(
                          'Important',
                          <Form.TextInput
                            name="important"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('important'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold !text-error',
                            }}
                            disable
                          />
                        )}
                        {renderVerticalRow(
                          'Organization Time Zone',
                          <Form.TextInput
                            name="organizationTimeZone"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('organizationTimeZone'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !text-error',
                            }}
                            disable
                          />
                        )}
                      </div>
                    </div>
                  </Box.Standard>
                  <Box.Standard className="h-full py-[30px]">
                    <div className="grid grid-cols-2 gap-10 mt-4 w-full">
                      <div className="flex w-full flex-col gap-y-[20px]">
                        {renderPart(
                          'Name Info',
                          <>
                            {renderVerticalRow(
                              '*First Name (Goes by)',
                              <Form.TextInput
                                name="firstName"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('firstName'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              '*Last Name',
                              <Form.TextInput
                                name="lastName"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('lastName'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Middle Name',
                              <Form.TextInput
                                name="middleName"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('middleName'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Initials',
                              <Form.TextInput
                                name="initials"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('initials'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Sort Name - Clin',
                              <Form.TextInput
                                name="sortName"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('sortName'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Given Name (First)',
                              <Form.TextInput
                                name="givenName"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('givenName'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Prefix',
                              <Form.SelectInput
                                name="prefix"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Sir',
                                    value: 'Sir',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Sir',
                                  value: 'Sir',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Suffix',
                              <Form.SelectInput
                                name="suffix"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Jr',
                                    value: 'Jr',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Jr',
                                  value: 'Jr',
                                }}
                              />
                            )}
                          </>,
                          true
                        )}
                        {renderPart(
                          'Education',
                          <>
                            {renderVerticalRow(
                              '*Clinician Degree',
                              <Form.SelectInput
                                name="degree"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'MD, PHD',
                                    value: 'MD, PHD',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'MD, PHD',
                                  value: 'MD, PHD',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Other Degrees',
                              <Form.SelectInput
                                name="otherDegree"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'MBA, MPH',
                                    value: 'MBA, MPH',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'MBA, MPH',
                                  value: 'MBA, MPH',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              '*Specialties',
                              <Form.SelectInput
                                name="specialties"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'PC - FP, UC',
                                    value: 'PC - FP, UC',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'PC - FP, UC',
                                  value: 'PC - FP, UC',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              '*Fellowships',
                              <Form.SelectInput
                                name="fellowships"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Fellowships',
                                    value: 'Fellowships',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Fellowships',
                                  value: 'Fellowships',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Clinician Record Status',
                              <Form.SelectInput
                                name="recordStatus"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Active',
                                    value: 'Active',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Active',
                                  value: 'Active',
                                }}
                              />
                            )}
                          </>,
                          true
                        )}
                        {renderPart(
                          'Identification',
                          <>
                            {renderVerticalRow(
                              '*Start Date',
                              <Form.DatePicker
                                name="startDate"
                                inputProps={{
                                  id: 'startDate',
                                  placeholder: '',
                                }}
                                date={form?.watch('startDate')}
                                onSelect={(value: Date) => {
                                  form.setValue('startDate', value);
                                }}
                                className="text-right"
                              />
                            )}
                            {renderVerticalRow(
                              '*Organization ID',
                              <Form.TextInput
                                name="organizationId"
                                inputProps={{
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                                  placeholder: '?',
                                }}
                                className="w-full"
                                disable
                              />
                            )}
                            {renderVerticalRow(
                              'Service Level',
                              <Form.SelectInput
                                name="serviceLevel"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Full Service',
                                    value: 'Full Service',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Full Service',
                                  value: 'Full Service',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Priority',
                              <Form.SelectInput
                                name="priority"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Neutral',
                                    value: 'Neutral',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Neutral',
                                  value: 'Neutral',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Ranking',
                              <Form.SelectInput
                                name="ranking"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Top 10%',
                                    value: 'Top 10%',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Top 10%',
                                  value: 'Top 10%',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Predictability',
                              <Form.SelectInput
                                name="predictability"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: '70%',
                                    value: '70%',
                                  },
                                ]}
                                defaultValue={{
                                  label: '70%',
                                  value: '70%',
                                }}
                              />
                            )}
                          </>,
                          true
                        )}
                      </div>
                      <div className="flex w-full flex-col gap-y-[20px]">
                        {renderPart(
                          'Key Info',
                          <>
                            {renderVerticalRow(
                              'Important',
                              <Form.TextInput
                                name="important"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('important'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold !text-error',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Organization Time Zone',
                              <Form.SelectInput
                                name="organizationTimeZone"
                                className="flex-1 bg-white"
                                options={TIMEZONE_OPTIONS}
                                defaultValue={userDetail?.timeZone}
                              />
                            )}
                          </>
                        )}
                        {renderPart(
                          'Organization',
                          <>
                            {renderVerticalRow(
                              '*Organization',
                              <Form.SelectInputV2
                                name="organization"
                                className="flex-1 bg-white"
                                defaultValue={organizationOption?.find(
                                  (org) =>
                                    org?.value === userDetail?.organizationId
                                )}
                                options={organizationOption}
                                disabled
                              />
                            )}
                            {renderVerticalRow(
                              '*Org Short',
                              <Form.TextInput
                                name="orgShort"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('orgShort'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              '*Org. Initials',
                              <Form.TextInput
                                name="orgInitials"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('orgInitials'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Location',
                              <Form.SelectInput
                                name="location"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Pana',
                                    value: 'Pana',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Pana',
                                  value: 'Pana',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Department',
                              <Form.SelectInput
                                name="department"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Family Medicine',
                                    value: 'Family Medicine',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Family Medicine',
                                  value: 'Family Medicine',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Site',
                              <Form.SelectInput
                                name="site"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: '29 Street',
                                    value: '29 Street',
                                  },
                                ]}
                                defaultValue={{
                                  label: '29 Street',
                                  value: '29 Street',
                                }}
                              />
                            )}
                          </>
                        )}
                        {renderPart(
                          'Preferences',
                          <>
                            {renderVerticalRow(
                              'Preferences',
                              <Form.SelectInput
                                name="preferences"
                                className="flex-1 bg-white"
                                options={[
                                  {
                                    label: 'Kim Conover',
                                    value: 'Kim Conover',
                                  },
                                ]}
                                defaultValue={{
                                  label: 'Kim Conover',
                                  value: 'Kim Conover',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Site Manager Cell',
                              <Form.TextInput
                                name="siteManagerCell"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('siteManagerCell'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Principal Nurse',
                              <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
                                <Form.SelectInput
                                  name="principalNurse"
                                  className="flex-1 bg-white"
                                  options={[]}
                                />
                                <img
                                  src={SVG.phone}
                                  alt="phone-icon"
                                  className="object-contain h-[15px] w-[15px]"
                                />
                              </div>
                            )}
                            {renderVerticalRow(
                              'Nurse Direct Phone',
                              <Form.TextInput
                                name="nurseDirectPhone"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('nurseDirectPhone'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Primary Document Specialist',
                              <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
                                <Form.SelectInput
                                  name="primaryDocumentSpecialist"
                                  className="flex-1 bg-white"
                                  options={[
                                    {
                                      label: 'Becky Byrns',
                                      value: 'Becky Byrns',
                                    },
                                  ]}
                                  defaultValue={{
                                    label: 'Becky Byrns',
                                    value: 'Becky Byrns',
                                  }}
                                />
                                <img
                                  src={SVG.phone}
                                  alt="phone-icon"
                                  className="object-contain h-[15px] w-[15px]"
                                />
                              </div>
                            )}
                            {renderVerticalRow(
                              'Primary Auditor',
                              <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
                                <Form.SelectInput
                                  name="primaryAuditor"
                                  className="flex-1 bg-white"
                                  options={[
                                    {
                                      label: 'April Walters',
                                      value: 'April Walters',
                                    },
                                  ]}
                                  defaultValue={{
                                    label: 'April Walters',
                                    value: 'April Walters',
                                  }}
                                />
                                <img
                                  src={SVG.phone}
                                  alt="phone-icon"
                                  className="object-contain h-[15px] w-[15px]"
                                />
                              </div>
                            )}
                            {renderVerticalRow(
                              'Turnaround Time',
                              <Form.TextInput
                                name="turnaroundTime"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('turnaroundTime'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                            {renderVerticalRow(
                              'Nurse Direct Phone',
                              <Form.TextInput
                                name="nurseDirectPhone"
                                className="flex-1 bg-white"
                                inputProps={{
                                  placeholder: '',
                                  value: form.getValues('nurseDirectPhone'),
                                  className:
                                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Box.Standard>
                  <Box.Standard className="h-full p-[15px]">
                    {renderTablePart(
                      'Attachments',
                      <Attachments
                        fontSize={fontSize}
                        rows={attachmentRows}
                        setRows={setAttachmentRows}
                        updateValue={updateAttachmentRows}
                      />
                    )}
                  </Box.Standard>
                  <Box.Standard>
                    {renderTablePart(
                      'Comments',
                      <Comments
                        fontSize={fontSize}
                        rows={commentRows}
                        setRows={setCommentRows}
                        updateValue={updateCommentRows}
                      />
                    )}
                  </Box.Standard>
                </>
              )}
              {selectPage === listFilter[1] && (
                <Box.Standard className="h-fit w-full px-[45px] py-[30px]">
                  <div className="grid grid-cols-2">
                    {renderPart(
                      'Contact Information',
                      <>
                        {renderVerticalRow(
                          '*First Name',
                          <p>{form.getValues('firstName')}</p>
                        )}
                        {renderVerticalRow(
                          'Goes By',
                          <p>{form.getValues('givenName')}</p>
                        )}
                        {renderVerticalRow(
                          'Middle Name',
                          <p>{form.getValues('middleName')}</p>
                        )}
                        {renderVerticalRow(
                          '*Last Name',
                          <p>{form.getValues('lastName')}</p>
                        )}
                        {renderVerticalRow(
                          'Clin Initials',
                          <p>{form.getValues('initials')}</p>
                        )}
                        {renderVerticalRow(
                          'Clin Sort Name',
                          <p>{form.getValues('sortName')}</p>
                        )}
                        {renderVerticalRow(
                          'Prefix',
                          <p>{form.getValues('prefix')}</p>
                        )}
                        {renderVerticalRow(
                          'Suffix',
                          <p>{form.getValues('suffix')}</p>
                        )}
                      </>
                    )}
                    {renderPart(
                      '',
                      <>
                        {renderVerticalRow(
                          '*Cell phone',
                          <p>{userDetail?.cellPhone}</p>
                        )}
                        {renderVerticalRow(
                          '*Email',
                          <p>{userDetail?.email}</p>
                        )}
                        {renderVerticalRow(
                          '*Organization',
                          <p>{form.getValues('organizationName')}</p>
                        )}
                        {renderVerticalRow(
                          'Org Initials',
                          <p>{form.getValues('orgInitials')}</p>
                        )}
                        {renderVerticalRow(
                          '*Clin Start Date',
                          <p>{form.getValues('startDate').toString()}</p>
                        )}
                        {renderVerticalRow(
                          '*Service Phone #',
                          <p>{userDetail?.servicePhone}</p>
                        )}
                        {renderVerticalRow(
                          'Clinician ID #',
                          <p>{form.getValues('organizationId')}</p>
                        )}
                        {renderVerticalRow(
                          'Priority',
                          <p>{form.getValues('priority')}</p>
                        )}
                      </>
                    )}
                  </div>
                </Box.Standard>
              )}
              {selectPage === listFilter[2] && (
                <div className="flex flex-col gap-y-4">
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      Patient Schedule
                    </p>
                    <GridTable
                      className="table-fixed"
                      checkboxes={false}
                      column={fakeColumn1}
                      row={fakeRow1}
                    />
                  </Box.Standard>
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      DS Notes Forecast
                    </p>
                    <GridTable
                      className="table-fixed"
                      checkboxes={false}
                      column={fakeColumn2}
                      row={fakeRow2}
                    />
                  </Box.Standard>
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      CO Notes Forecast
                    </p>
                    <GridTable
                      className="table-fixed"
                      checkboxes={false}
                      column={fakeColumn2}
                      row={fakeRow2}
                    />
                  </Box.Standard>
                </div>
              )}
              {selectPage === listFilter[3] && (
                <div className="flex flex-col gap-y-4">
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      DS Notes Forecast
                    </p>
                    <GridTable
                      className="table-fixed"
                      checkboxes={false}
                      column={fakeColumn2}
                      row={fakeRow2}
                    />
                  </Box.Standard>
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      DS Assignments
                    </p>
                    <GridTable
                      className="table-fixed"
                      checkboxes={false}
                      column={fakeColumn3}
                      row={fakeRow3}
                    />
                  </Box.Standard>
                </div>
              )}
              {selectPage === listFilter[4] && (
                <div className="flex flex-col gap-y-4">
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      CO Notes Forecast
                    </p>
                    <GridTable
                      className="table-fixed"
                      checkboxes={false}
                      column={fakeColumn2}
                      row={fakeRow2}
                    />
                  </Box.Standard>
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      DS Assignments
                    </p>
                    <GridTable
                      className="table-fixed"
                      checkboxes={false}
                      column={fakeColumn3}
                      row={fakeRow3}
                    />
                  </Box.Standard>
                </div>
              )}
              {selectPage === listFilter[5] && (
                <div className="flex flex-col gap-y-4">
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      Note Preferences
                    </p>
                    <Builder.Content
                      html={notePreferencesContent}
                      onSave={(html) =>
                        handleSaveContent(html, 'Note Preferences')
                      }
                      isEnableEdit
                    />
                  </Box.Standard>
                  <Box.Standard className="h-fit w-full p-[5px] flex flex-col gap-y-2">
                    <p className="w-full font-bold flex items-center text-center justify-center">
                      Code Preferences
                    </p>
                    <Builder.Content
                      html={codePreferencesContent}
                      onSave={(html) =>
                        handleSaveContent(html, 'Code Preferences')
                      }
                      isEnableEdit
                    />
                  </Box.Standard>
                </div>
              )}
              {selectPage === listFilter[6] && (
                <Box.Standard className="h-full w-full py-[30px] px-[45px] flex flex-col gap-y-2">
                  <Builder.Content
                    html={smartPhrasesContent}
                    onSave={(html) => handleSaveContent(html, 'Smart Phrases')}
                    isEnableEdit
                  />
                </Box.Standard>
              )}
              {selectPage === listFilter[7] && (
                <Box.Standard className="h-full w-full py-[30px] px-[45px] flex flex-col gap-y-2">
                  <Builder.Content
                    html={smartNotesContent}
                    onSave={(html) => handleSaveContent(html, 'Smart Notes')}
                    isEnableEdit
                  />
                </Box.Standard>
              )}
              {selectPage === listFilter[8] && (
                <Box.Standard>
                  {renderTablePart(
                    'Comments',
                    <Comments
                      fontSize={fontSize}
                      rows={commentRows}
                      setRows={setCommentRows}
                      updateValue={updateCommentRows}
                    />
                  )}
                </Box.Standard>
              )}
              {selectPage === listFilter[9] && (
                <Box.Standard>
                  {renderTablePart(
                    'Attachments',
                    <Attachments
                      fontSize={fontSize}
                      rows={attachmentRows}
                      setRows={setAttachmentRows}
                      updateValue={updateAttachmentRows}
                    />
                  )}
                </Box.Standard>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default ClinicianDetailPage;
